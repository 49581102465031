// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-helloworld-tsx": () => import("./../../../src/pages/helloworld.tsx" /* webpackChunkName: "component---src-pages-helloworld-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mergeconf-tsx": () => import("./../../../src/pages/mergeconf.tsx" /* webpackChunkName: "component---src-pages-mergeconf-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-return-tsx": () => import("./../../../src/pages/return.tsx" /* webpackChunkName: "component---src-pages-return-tsx" */),
  "component---src-pages-shipping-tsx": () => import("./../../../src/pages/shipping.tsx" /* webpackChunkName: "component---src-pages-shipping-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-produkt-tsx": () => import("./../../../src/templates/produkt.tsx" /* webpackChunkName: "component---src-templates-produkt-tsx" */)
}

